import React from 'react';
import { currentUserService } from 'subjects/common/CurrentUserService';
import UserDropDown from './UserDropDown';
import { talkdeskFeatures as tdFeatures } from '../Common/TalkdeskUtils';
import { sidebarToggleClicked$ } from '../NavMenu/NavMenu';
import Talkdesk from '../Talkdesk/Talkdesk';
import CallOverlay from '../Talkdesk/CallOverlay';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: true,
            featureFlags: null,
        };
    }

    componentDidMount() {
        this.subscriptions = [];
        this.subscriptions.push(
            currentUserService.get().subscribe((u) => {
                if (u && u.crmFeatureFlags) {
                    this.setState({ featureFlags: u.crmFeatureFlags });
                }
            })
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    toggleClick = () => {
        this.setState((prevState) => {
            sidebarToggleClicked$.next(!prevState.toggle);
            return { toggle: !prevState.toggle };
        });
    };

    render() {
        const ctiEnabled = tdFeatures.featureEnabled(this.state.featureFlags, tdFeatures.ctiIntegration);
        const inboundCallsEnabled = tdFeatures.featureEnabled(this.state.featureFlags, tdFeatures.inboundCalls);
        const outboundCallsEnabled = tdFeatures.featureEnabled(this.state.featureFlags, tdFeatures.outboundCalls);
        const loginRemindersEnabled = tdFeatures.featureEnabled(this.state.featureFlags, tdFeatures.loginReminders);
        return (
            <>
                <div className="main-header" id="header-section" data-testid='header'>
                    <div className={`logo ${this.state.toggle ? 'logo-toggle-on' : 'logo-toggle-off'}`}>
                        <a href="/" className="main-logo">
                            <img src="/img/logo.png" alt="logo" width="40px" height="40px" />
                        </a>
                    </div>
                    <ul className="header-list">
                        <li
                            className={`float-left header-style icon-menu ${
                                this.state.toggle ? 'hamburger-toggle-on' : 'hamburger-toggle-off'
                            }`}
                            id="hamburger"
                            onClick={this.toggleClick}
                        ></li>
                        <li className={'header-style float-left'}>
                            <CallOverlay />
                        </li>
                        <li className="header-style float-right user">
                            <UserDropDown talkdeskEnabled={ctiEnabled} />
                        </li>
                    </ul>
                </div>
                <Talkdesk
                    ctiEnabled={ctiEnabled}
                    inboundCallsEnabled={inboundCallsEnabled}
                    outboundCallsEnabled={outboundCallsEnabled}
                    loginReminders={loginRemindersEnabled}
                />
            </>
        );
    }
}
