import WebEnrollmentAdminPortal from 'components/AvaEzm/MemberEnrollment/AdminAndSupport/WebEnrollmentAdminPortal';
import WebEnrollmentPortal from 'components/AvaEzm/MemberEnrollment/AdminAndSupport/WebEnrollmentPortal';
import ModuleLayout, { syncfusionTabItemsConverter } from 'components/Common/ModuleLayout/ModuleLayout';
import AuditHistory from './AuditHistory';

const TAB_ITEMS = [
    {
        header: {
            text: 'Automation Monitor',
        },
        cssClass: 'nonClosableTab',
        content: () => (
            <div className="tabDiv">
                <WebEnrollmentAdminPortal />
            </div>
        ),
    },
    {
        header: {
            text: 'Requeue',
        },
        cssClass: 'nonClosableTab',
        content: () => (
            <div className="tabDiv">
                <WebEnrollmentPortal />
            </div>
        ),
    },
    {
        header: {
            text: 'Audit History',
        },
        cssClass: 'nonClosableTab',
        content: () => (
            <div className="tabDiv">
                <AuditHistory />
            </div>
        ),
    },
];

const AdminAndSupportAdminPortal = () => {
    return (
        <section>
            <ModuleLayout header="Web Enrollment Portal" tabs={syncfusionTabItemsConverter(TAB_ITEMS)} />
        </section>
    );
};

export default AdminAndSupportAdminPortal;
